import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface IButton {
  variant?: 'text' | 'contained' | 'outlined' | 'plain';
  color?: 'primary' | 'neutral' | 'dark' | 'green';
  size?: 'default' | 'small';
  disabled?: boolean;
  className?: string;
  children?: ReactNode;
  [key: string]: any;
}

const Button: FC<IButton> = ({
  variant = 'contained',
  color = 'primary',
  size = 'default',
  disabled = false,
  className,
  children,
  ...props
}) => {
  interface IStyles {
    base: string;
    disabled: string;
    size: {
      [key in Exclude<IButton['size'], undefined>]: string;
    };
    variant: {
      [key in Exclude<IButton['variant'], undefined>]: {
        [colorKey in Exclude<IButton['color'], undefined>]: string;
      };
    };
  }

  const styles: IStyles = {
    base: 'rounded-full transition-all',
    disabled: 'opacity-40 pointer-events-none',
    size: {
      default: 'py-2.5 px-6 text-base font-medium',
      small: 'py-[5px] px-5 text-base font-medium',
    },
    variant: {
      text: {
        primary: 'text-primary hover:text-black transition-all',
        neutral: 'transition-all',
        dark: 'transition-all',
        green: 'transition-all',
      },
      contained: {
        primary:
          'bg-[#0071E3] border border-[#0071E3] hover:bg-[#005DCF] hover:border-[#005DCF] focus:bg-[#0049BB] focus:border-[#005DCF] text-white',
        neutral:
          'bg-[#D7D7D7] border border-[#D7D7D7] hover:bg-[#D7D7D7] hover:border-[#D7D7D7] focus:bg-[#D7D7D7] focus:border-[#D7D7D7] text-black',
        dark: 'bg-black border border-black hover:bg-black hover:border-black focus:bg-black focus:border-black text-white',
        green:
          'bg-[#476E30] border border-[#476E30] hover:bg-[#476E30] hover:border-[#476E30] focus:bg-[#476E30] focus:border-[#476E30] text-white',
      },
      outlined: {
        primary:
          'bg-[#0071E3]/0 border border-[#0071E3] hover:bg-[#005DCF] hover:border-[#005DCF] hover:text-white focus:bg-[#0049BB] focus:border-[#005DCF] focus:text-white text-[#0071E3]',
        neutral:
          'bg-[#D7D7D7]/0 border border-[#A7A7A7] text-black hover:bg-[#D7D7D7]/0 hover:border-black focus:bg-[#D7D7D7]',
        dark: 'bg-black/0 border border-black hover:bg-black hover:border-black hover:text-white focus:bg-black focus:border-black focus:text-white text-black',
        green:
          'bg-[#476E30]/0 border border-[#476E30] hover:bg-[#476E30] hover:border-[#476E30] hover:text-white focus:bg-[#476E30] focus:border-[#476E30] focus:text-white text-[#476E30]',
      },
      plain: {
        primary:
          'bg-[#0071E3]/0 border border-[#0071E3]/0 focus:bg-[#0071E3] focus:border-[#0071E3] text-[#0071E3] focus:text-white',
        neutral:
          'bg-[#D7D7D7]/0 border border-[#D7D7D7]/0 focus:bg-[#D7D7D7] focus:border-[#D7D7D7] text-black',
        dark: 'bg-black/0 border border-black/0 focus:bg-black focus:border-black focus:text-white text-black',
        green:
          'bg-[#476E30]/0 border border-[#476E30]/0 focus:bg-[#476E30] focus:border-[#476E30] text-[#476E30] focus:text-white',
      },
    },
  };

  return (
    <button
      className={twMerge(
        `${styles.base} ${styles.size[size]} ${styles.variant[variant][color]} ${disabled ? styles.disabled : ''}`,
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
